<template>
  <section id="triage-rules">
    <!-- PAGE -->
    <b-card title="Settings">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Triage rule name"
            label-for="input_triage_rule_name"
            label-cols-md="12"
          >
            <b-form-input
              id="input_triage_rule_name"
              placeholder="Set triage rule name"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Condition(s)"
            label-for="input_conditions"
            label-cols-md="12"
          >
            <v-select
              v-model="selectedConditions"
              id="input_conditions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="title"
              :options="optionsConditions"
              placeholder="Set condition(s)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <!-- CRITERIA -->
    <b-card>
      <h4>Criteria</h4>
      <div>
        <div>
          <b-form
            ref="form"
            :style="{ height: trHeight }"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <!-- Row Loop -->
            <b-row
              class="mt-2 mt-md-2"
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col md="1">
                <b-badge variant="secondary" style="padding: 0.9rem">
                  {{ index == 0 ? "IF" : "AND" }}
                </b-badge>
              </b-col>
              <!-- Item Name -->
              <b-col md="9">
                <v-select
                v-model="item.selectedStatement"
                  :options="[
                    { label: 'All of these answers', value: 'all' },
                    { label: 'None of these answers', value: 'none' },
                    { label: 'At least 1 of these answers', value: 'atleast1' },
                    { label: 'At least 2 of these answers', value: 'atleast2' },
                    { label: 'At least 3 of these answers', value: 'atleast3' },
                  ]"
                  :dir="ltr"
                />
              </b-col>

              <!-- Remove Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button style="width: 100%;"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-0"
                  @click="removeItem(index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Delete</span>
                </b-button>
              </b-col>

              <!-- Answers -->
              <b-col md="1" class="mt-1 mt-md-1"></b-col>

              <b-col md="9" class="mt-1 mt-md-1">
                <div v-if="index === 0">
                  <!-- Deze buitenste 'v-if' div mag weg, is namelijk alleen om het voorbeeld bij de eerste (bovenste) weergave te tonen voorbeeld -->
                  <p>
                    <a href="#" @click="active = !active"
                      >Heeft u zoveel last dat u het aangedane gebied absoluut
                      niet kunt belasten?</a
                    >
                  </p>
                  <div class="batch_container">
                    <b-badge variant="success">
                      Ja <feather-icon icon="XIcon"
                    /></b-badge>
                    <b-badge variant="success">
                      Ja, dat heb ik inderdaad <feather-icon icon="XIcon"
                    /></b-badge>
                    <b-badge variant="success">
                      Ja, dat heb ik een beetje <feather-icon icon="XIcon"
                    /></b-badge>
                    <b-badge variant="success">
                      Ja <feather-icon icon="XIcon"
                    /></b-badge>
                    <b-badge variant="success">
                      Ja, dat heb ik inderdaad <feather-icon icon="XIcon"
                    /></b-badge>
                    <b-badge variant="success">
                      Ja, dat heb ik een beetje <feather-icon icon="XIcon"
                    /></b-badge>
                  </div>

                  <p class="extraMargin">
                    <a href="#" @click="active = !active"
                      >Hoe vaak heeft u last van deze klachten?</a
                    >
                  </p>
                  <div class="batch_container">
                    <b-badge variant="success">
                      Soms <feather-icon icon="XIcon"
                    /></b-badge>
                    <b-badge variant="success">
                      Regelmatig <feather-icon icon="XIcon"
                    /></b-badge>
                    <b-badge variant="success">
                      Vaak <feather-icon icon="XIcon"
                    /></b-badge>
                    <b-badge variant="success">
                      Altijd <feather-icon icon="XIcon"
                    /></b-badge>
                  </div>
                </div>
              </b-col>

              <!-- Select Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button style="width: 100%;"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-success"
                  class="mt-0 mt-md-0"
                  @click="active = !active"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>Select</span>
                </b-button>
              </b-col>

              <!-- Answers -->
              <b-col md="1" class="mt-1 mt-md-1"></b-col>

              <b-col cols="12" class="mt-2 mt-md-2">
                <hr />
              </b-col>
            </b-row>
          </b-form>
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Add New</span>
        </b-button>
      </div>
    </b-card>

    <!-- Deze toevoegen als er op de +Add OR button geklikt wordt, en de b-card vullen met eerste IF statement -->
    <div id="tempOr" style="display: none">
      <div class="orSeperator text-secondary">
        <h3 class="text-secondary">OR</h3>
      </div>

      <b-card>
        Hier bovenstaande functionaliteit in zijn geheel herhalen
      </b-card>
    </div>

    <div class="addOrContainer text-secondary">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        onclick="document.getElementById('tempOr').style.display = 'block';"
      >
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Add OR</span>
      </b-button>
    </div>

    <!-- CRITERIA MATCH SETTINGS -->
    <b-row class="mt-2 mt-md-2">
      <b-col md="6">
        <b-card class="extraMarginHalf">
          <h4>If criteria match</h4>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Urgency"
                label-for="input_match_urgency"
                label-cols-md="4"
              >
                <v-select
                  id="input_match_urgency"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionsUrgency"
                  placeholder="Set urgency"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Advice"
                label-for="input_match_advice"
                label-cols-md="4"
              >
                <v-select
                  id="input_match_advice"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionsAdvice"
                  placeholder="Set advice"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Exercises"
                label-for="input_match_advice"
                label-cols-md="4"
              >
                <v-select
                  id="input_match_exercises"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionsExercises"
                  placeholder="Set exercises"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card class="extraMarginHalf">
          <h4>Else</h4>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Urgency"
                label-for="input_match_else_urgency"
                label-cols-md="4"
              >
                <v-select
                  id="input_match_else_urgency"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionsUrgency"
                  placeholder="Set urgency"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Advice"
                label-for="input_match_else_advice"
                label-cols-md="4"
              >
                <v-select
                  id="input_match_else_advice"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionsAdvice"
                  placeholder="Set advice"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Exercises"
                label-for="input_match_else_exercises"
                label-cols-md="4"
              >
                <v-select
                  id="input_match_else_exercises"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionsExercises"
                  placeholder="Set exercises"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- /PAGE -->

    <!-- SIDEBAR -->
    <b-sidebar
      width="40%"
      right
      shadow
      parent="body"
      default-index="1"
      spacer
      v-model="active"
      title="Select answers"
      class="sidebar"
    >
      <div class="px-3 py-2">
        <p>Search for the questions/answers you want to add.</p>
        <h6>Search</h6>
        <b-form-group>
          <b-form-input id="inputName" placeholder="Search term(s)" />
        </b-form-group>

        <b-form-select
          v-model="questionSearchResultsSelected"
          :options="questionSearchResults"
          :select-size="4"
        />

        <b-card class="extraMargin">
          <h6>Voer de volgende test uit</h6>
          <p>Let goed op uw houding tijdens het uitvoeren van de test.</p>

          <div>
            <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/-SJ161PY8sY?rel=0"
              allowfullscreen
            />
          </div>

          <p class="extraMargin">Geeft dit herkenbare klachten?</p>

          <div class="checkbox_container">
            <b-form-checkbox v-model="checkbox1" value="1">Ja</b-form-checkbox>
            <b-form-checkbox v-model="checkbox1" value="2"
              >Ja, dat heb ik inderdaad</b-form-checkbox
            >
            <b-form-checkbox v-model="checkbox1" value="3"
              >Ja, dat heb ik een beetje</b-form-checkbox
            >
            <b-form-checkbox v-model="checkbox1" value="4"
              >Ja, dat heb ik inderdaad</b-form-checkbox
            >
            <b-form-checkbox v-model="checkbox1" value="5">Nee</b-form-checkbox>
          </div>
        </b-card>
      </div>
    </b-sidebar>
    <!-- /SIDEBAR -->
  </section>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BButton,
  BBadge,
  BSidebar,
  BFormCheckbox,
  BEmbed,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";

import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

export default {
  data() {
    return {
      // optionConditions: [
      //   { title: "All of these answers" },
      //   { title: "None of these answers" },
      //   { title: "At least 1 of these answers" },
      //   { title: "At least 2 of these answers" },
      //   { title: "At least 3 of these answers" },
      // ],
      active: false,
      nextTodoId: 2,
      checkbox1: ["1", "2", "3"],
      questionSearchResultsSelected: 1,
      questionSearchResults: [
        { value: 1, text: "Voer de volgende test uit" },
        { value: 2, text: "Heeft u last van nachtelijke pijn?" },
        { value: 3, text: "Kunt u uw elleboog pijnoos strekken?" },
        { value: 4, text: "Hef uw arm tot 90 graden" },
      ],
      items: [
        {
          id: 1,
          selectedStatement: "All of these answers",
          prevHeight: 0,
        },
      ],
      optionsUrgency: [{ title: "Urgent" }, { title: "Routine" }],
      optionsAdvice: [{ title: "Do this" }, { title: "Do that" }],
      optionsExercises: [
        { title: "Shoulder basic exercises" },
        { title: "Shoulder RSI exercises" },
      ],
      optionsConditions: [
        { value: '1', title: "Osteoarthrosis of the shoulder" },
        { value: '2', title: "RSI" },
        { value: '3', title: "Shoulder instability" },
      ],
      selectedConditions: ['2']
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BBadge,
    vSelect,
    BSidebar,
    BFormCheckbox,
    BEmbed,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods : {
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.repeater-form {
  //overflow: hidden;
  transition: 0.35s height;
}

.batch_container .badge {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.checkbox_container .custom-checkbox {
  margin-bottom: 0.5rem;
}

//Deze regel is nodig om het pijltje van de dropdowns geforceerd weer te geven. Werkt mogelijk momenteel niet doordat er wat errors op de pagina zijn.
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5) !important;
    // cursor: pointer; // ADDED THIS LINE
    // margin-top: 0.15rem;  
}

.extraMarginHalf {
  margin-top: 0.5rem;
}

.extraMargin {
  margin-top: 1rem;
}

.addOrContainer {
  text-align: right;
  margin-bottom: 1rem;
}

.orSeperator {
  text-align: center;
  margin-bottom: 2rem;
}
</style>